import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import config from "../config";
import AuthenticationService from "../Services/AuthenticationService";
import createAuthRefreshInterceptor from "axios-auth-refresh";

/**
 * This function creates axios instances with necessary interceptors attached and common configurations. This allows for
 * easily creating axios instances that have a base level of equivalent configurations. You may need a special axios
 * instance if, for example, you're handling authentication refresh flows. See later in this file for examples and
 * comments.
 * @return {AxiosInstance} An Axios instance with interceptors and common configurations.
 */
export const getAxiosInstance = () => {
  const instance = axios.create({
    baseURL: config.EXTERNAL_URL_BASE,
    // Set so Axios always sends creds in cross-site Access-Control reqs. Only necessary for CORS, aka when not prod
    withCredentials: process.env.NODE_ENV !== "production",
  });

  instance.interceptors.request.use(setCsrfHeader);

  instance.interceptors.response.use(retrieveCsrfToken);

  instance.interceptors.response.use(updateUser);

  return instance;
};

/**
 * An Axios interceptor that sets the XSRF-TOKEN custom header for the double-submit cookie CSRF mitigation. The CSRF
 * token is retrieved from session storage and set on the request.
 * @param {AxiosRequestConfig} requestConfig An Axios request configuration.
 * @returns {AxiosRequestConfig} Returns the `requestConfig` object so that the request can continue.
 */
const setCsrfHeader = (requestConfig) => {
  let csrfToken = sessionStorage.getItem("csrfToken");

  if (["post", "put", "patch", "delete"].includes(requestConfig.method)) {
    if (!csrfToken) {
      console.warn(
        `Attempted to set XSRF-TOKEN header for request to [ ${requestConfig.url} ], but token not present`,
      );
    }
    requestConfig.headers["XSRF-TOKEN"] = csrfToken;
  }
  return requestConfig;
};

/**
 * Retrieves a CSRF token from the body of responses received from token refresh or login requests.
 * @param {AxiosResponse} response The response object from the server. The CSRF token will be inside it.
 * @param {string} response.data.csrfToken The CSRF token to store. If not present, the existing CSRF token will be
 * overwritten with `undefined`. The user will eventually be forced to re-login
 * @return {AxiosResponse} The `response` object so processing can continue.
 */
const retrieveCsrfToken = (response) => {
  const isRefreshOrLogin =
    response.config.url.endsWith("/refresh") ||
    response.config.url.endsWith("/login");
  if (response.status === 200 && isRefreshOrLogin) {
    if (!response.data.csrfToken) {
      console.warn("Expected to see a CSRF token, but it is not present.");
    }

    sessionStorage.setItem("csrfToken", response.data.csrfToken);

    AuthenticationService.channel.postMessage({
      request: "update-session",
      sessionData: { csrfToken: response.data.csrfToken },
    });

    if (response.data.csrfToken) {
      // Remove the attribute from the response body
      delete response.data.csrfToken;
    }
  }
  return response;
};

/**
 * An Axios interceptor for capturing responses that signal an update to the client-stored user representation. If an
 * update arrives, this interceptor extracts the update, removes it from the response body, and makes the necessary
 * updates.
 * @param {AxiosResponse} response The Axios response object.
 * @param {object} response.data.userUpdate The update to make to the user object. Only a subset of keys is supported for update.
 * @param {boolean} response.data.userUpdate.accountAccess `false` if the user's account is suspended, `true` otherwise.
 */
const updateUser = (response) => {
  if (response.status === 200) {
    if (response.data.userUpdate) {
      let existingUser = AuthenticationService.getUser();
      const userUpdate = response.data.userUpdate;
      // Only a subset of attrs may be updated. Here we prevent any other attrs from being included in the update.
      delete userUpdate._id;
      Object.assign(existingUser, response.data.userUpdate);
      AuthenticationService.setUser(existingUser);
      delete response.data.userUpdate;
    }
  }
  return response;
};

/**
 * @type {AxiosInstance} This instance handles all requests the frontend makes except for requests to refresh the auth
 * creds, and to login.
 */
export const axiosInstance = getAxiosInstance();

/**
 * A separate axios instance is necessary for these requests as the instance exported by this module, `axiosInstance`,
 * has a special interceptor attached to it to handle auth cred refresh. The interceptor will pause all requests coming
 * through the instance until auth creds have been refreshed. This means a refresh request itself would pause, hanging
 * the system.
 *
 * Similarly, a separate instance is necessary for login attempts as a user must reauthenticate if an auth cred refresh
 * fails.
 *
 * @member {function} msAuthFailListener A function that takes no arguments and returns a promise. The function will
 * @type {AxiosInstance} This instance handles requests to the /refresh endpoint to refresh auth creds.
 * be called when an auth token refresh request fails. The returned promise will be used to stall any subsequent
 * network requests.
 */
const axiosRefreshInstance = getAxiosInstance();

// This handles retrying requests on authn (401) failures as long as the failed request wasn't an access token refresh
// request. If the refresh request succeeds, the failed request is retried and its result is returned to the original
// requestor.
createAuthRefreshInterceptor(
  axiosInstance,
  (error) => {
    if (error.config.url.endsWith("/refresh")) {
      console.debug("A failed /refresh request made it to the wrong handler");
    }

    console.debug(
      `Request to [ ${error.config.url} ] failed - calling /refresh`,
    );

    return axiosRefreshInstance({
      url: "/refresh",
      method: "GET",
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (axiosRefreshInstance.msAuthFailListener) {
            return axiosRefreshInstance.msAuthFailListener();
          } else {
            console.debug("REJECTED AUTOMATICALLY");
            return Promise.reject(
              new Error("No auth fail listener registered - can't recover"),
            );
          }
        } else {
          console.debug(
            `Refresh failed with status [ ${error.response.status} ]`,
          );
          return Promise.reject(
            new Error(
              `Refresh failed with status [ ${error.response.status} ]`,
            ),
          );
        }
      } else {
        console.debug(
          "Authentication refresh request failed for unknown reason.",
        );
        console.debug(error);
        return Promise.reject(error);
      }
    });
  },
  {
    // Make sure the stalled requests have a CSRF header set on them before they are fired
    onRetry: (requestConfig) => {
      return setCsrfHeader(requestConfig);
    },
  },
);

/**
 * Decorates the `axiosInstance` exported by this module with a listener that will be notified when calls to refresh
 * authn tokens fails. The function must return a promise.
 * @param {function} authFailListener A function that returns a promise. The function will be called when an attempt to
 * refresh an authentication token fail.
 */
export const registerAuthFailListener = (authFailListener) => {
  axiosRefreshInstance.msAuthFailListener = authFailListener;
};
