export default {
  displayMode: false,
  // EXTERNAL_URL_BASE: "http://localhost:9000/api/v1",
  //EXTERNAL_URL_BASE: "https://44.239.74.226:9000/api/v1",
  EXTERNAL_URL_BASE: "https://www.mathsolutely.com/api/v1",
  //STRIPE_PUBLIC_API:
  //  "pk_test_51Ja7p1LhR9AJrbLv6SOzT8MdOFCTy5QSt0SKAOU5b7UX9ch4RfVYzmBzIpUR9oiYohXrS9aw1UaopHMYZmX1oCHa00xPyKQsXI", // test
  STRIPE_PUBLIC_API:
    "pk_live_51Ja7p1LhR9AJrbLv5sOKtLao7KqmPK82nsbrqQlvNfbXOTPYafrblN8NhdKTKaz0wEbCqnHiRYKQVwM7eK5c6bTY00OEXxTtov", // live
  cssTransitionOptions: {
    component: "div",
    transitionName: "TabsAnimation",
    transitionAppear: true,
    transitionAppearTimeout: 0,
    transitionEnter: false,
    transitionLeave: false,
  },
  communications: {
    supportEmailAddress: "contact@mathsolutely.com",
    contactEmailAddress: "contact@mathsolutely.com",
  },
  testOptions: {
    timeLimitInMinutes: 35,
    gridInAnswerMaxLength: 5,
    //Allow numbers, forward slash, negative sign and dot.  The "|^-$/" allows the user to validly start with just a negative sign.
    //eslint-disable-next-line
    gridInAnswerRegex: /^-?[0-9./]$|^-$/,
    normalTestHistoryScoresPerPage: 12,
    mobileTestHistoryScoresPerPage: 3,
    tabletTestHistoryScoresPerPage: 5,
    mobileMiniLineGraphScoresPerPage: 5,
    lineGraphScoresPerPage: 7,
  },
  mathjaxOptions: {
    options: {
      renderActions: {
        addMenu: [], //disable the MathJax context menu
      },
    },
    tex: {
      //Allow for processing inline and escaped formulas
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      processEscapes: true,
    },
  },
  planOptions: {
    numTestsLeftForExpirationWarning: 1,
    numDaysLeftForExpirationWarning: 7,
  },
  refundOptions: {
    numDaysRefundAllowed: 7,
  },
  referralOptions: {
    promotionText:
      "Your purchase makes you eligible for a free Amazon gift card!",
    promotionFinePrint:
      "Limited time offer. Applies when another user (not you) purchases an Unlimited package, applies your referral" +
      " code, and doesn't cancel within the refund period. Gift card processing occurs every 30 days.",
  },
  goalOptions: {
    descriptionLength: 100,
  },
  commit: "4162c618",
};
