import { axiosInstance, getAxiosInstance } from "Mathsolutely/Util/api";

/**
 * A representation of the current user.
 * @typedef {Object} User
 * @property {boolean} accountAccess True if the user is in good standing, false if the user is suspended
 * @property {Object} location An object capturing the user's submitted location data.
 * @property {string} firstName The user's first name.
 * @property {string} lastName The user's last name.
 * @property {'Administrator'|'Monitor'|'Student'} kind The type/role of the user. Enumerated.
 * @property {string} email The user's email address.
 * @property {string} _id The user's ID.
 */

const AuthenticationService = {
  // BroadcastChannel for syncing session data across tabs
  channel: new BroadcastChannel("sync-session-storage"),

  /**
   * Get the session data you want to sync between tabs.
   * @returns {Object|null} The session data, or null if no session data exists.
   */
  getSessionData: function () {
    const keys = ["user", "csrfToken"]; // List all the sessionStorage keys you want to sync
    const data = keys.reduce((obj, key) => {
      const value = sessionStorage.getItem(key);
      return value === null ? obj : { ...obj, [key]: value };
    }, {});
    return Object.keys(data).length > 0 ? data : null; // Return null if there's no data
  },

  /**
   * Set up the listener to handle sync requests from other tabs.
   */
  listenSync: function () {
    this.channel.onmessage = (e) => {
      if (e.data.request === "sync") {
        // Sync request: Send the current session data to the other tab
        const sessionData = this.getSessionData();

        if (sessionData) {
          this.channel.postMessage({ sessionData, response: "sync" });
        }
      } else if (e.data.request === "update-session") {
        // When a tab sends a message to update sessionStorage across tabs
        this.updateSessionStorage(e.data.sessionData);
      }
    };
  },

  /**
   * Request session data from other tabs.
   * @returns {Promise<Object>} The session data from another tab or null if no response.
   */
  requestSessionData: function () {
    return new Promise((resolve, reject) => {
      try {
        const timerId = setTimeout(reject, 5000); // Timeout after 500ms if no response
        this.channel.onmessage = (e) => {
          if (e.data.response !== "sync") return;
          clearTimeout(timerId);
          resolve(e.data.sessionData);
        };
        this.channel.postMessage({ request: "sync" });
      } catch (err) {
        reject(err);
      }
    });
  },

  /**
   * Sync session data across tabs if it doesn't exist in sessionStorage yet.
   */
  syncSessionData: async function () {
    if (!this.getSessionData()) {
      const sessionData = await this.requestSessionData();
      if (sessionData) {
        Object.keys(sessionData).forEach((key) => {
          sessionStorage.setItem(key, sessionData[key]);
        });
        this.listenSync(); // Start listening to sync data after we've got it
      }
    } else {
      this.listenSync(); // Start listening to sync data if it's already present
    }
  },

  /**
   * Sends authentication credentials to the server to authenticate a user.
   * @param username The user's username.
   * @param password The user's password.
   * @return {Promise<{success: boolean, user: User | null}>}
   */
  authenticateUser: async function (username, password) {
    const axios = getAxiosInstance();
    const options = {
      url: "/entry/login",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: username,
        password: password,
      },
    };
    try {
      const response = await axios(options);

      // don't set the user if they haven't already confirmed their email.
      if (response.status !== 202) {
        this.setUser(response.data.user);
      }

      return {
        success: true,
        user: response.data.user,
        statusCode: response.status, // Add status code here
      };
    } catch (error) {
      // the 500 is a good generic 'catch-all' HTTP server error code if no value to put in error
      return {
        success: false,
        user: null,
        error: error.response ? error.response.status : 500,
      };
    }
  },

  /**
   * Checks if the user is logged in. Whether a user is logged in is determined by the user's JWT access token. If the
   * access token is not expired, the user can be considered logged in. If the token is expired, then the client (us)
   * sends the user's JWT refresh token. If the JWT refresh token is not expired, the user is logged in and receives
   * a new JWT access token. If the refresh token is also expired, the user will be forced to log in again.
   * @return {Promise<boolean>} Returns true if the server says the user can be considered logged in, false otherwise.
   */
  loggedIn: async function () {
    const user = this.getUser();
    if (!!user) {
      return true;
    }

    try {
      // If the JWT access token is valid, then the user can be considered logged in, and we can proceed. This is
      // a very quick, lightweight request.
      const response = await axiosInstance({
        url: "/login-status",
        method: "GET",
        skipAuthRefresh: true,
      });
      if (response.status === 200) {
        // When the user closes the tab the session storage is lost but the cookies will remain. This will
        // ensure the user is able to get their info once the cookies are validated on the server.
        this.setUser(response.data.user);
        axiosInstance({
          url: "/refresh",
          method: "GET",
        });
        return true;
      } else {
        // TODO If the response isn't a 200 code, not sure what to do - It will still be a successful code
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  /**
   * Set the current user in session storage and trigger a sync across tabs.
   * @param {User} user
   */
  setUser: function (user) {
    sessionStorage.setItem("user", JSON.stringify(user));

    // Notify other tabs to update their session data
    this.channel.postMessage({
      request: "update-session",
      sessionData: { user: JSON.stringify(user) },
    });
  },

  /**
   * Returns the locally-stored user object
   * @return {User} The current user.
   */
  getUser: function () {
    return JSON.parse(sessionStorage.getItem("user"));
  },

  /**
   * Updates specific fields for the current user in session storage.
   * @param {Object} updatedFields Object containing the fields to update in user
   */
  updateSessionStorageUser: function (updatedFields) {
    let user = this.getUser();
    if (!user) {
      console.error("No existing user found");
      return;
    }

    user = { ...user, ...updatedFields };

    sessionStorage.setItem("user", JSON.stringify(user));

    // Notify other tabs to update their session data
    this.channel.postMessage({
      request: "update-session",
      sessionData: { user: JSON.stringify(user) },
    });
  },

  /**
   * Logs the user out and clears the session data.
   */
  logout: async function () {
    sessionStorage.clear();
    const options = {
      url: "/logout",
      method: "GET",
    };

    // TODO What to do if axios call fails?
    await axiosInstance(options)
      .then(() => this.setUser(null))
      .catch((error) => console.warn("The logout call failed"));
  },

  /**
   * Updates session storage with the given data.
   * @param {Object} sessionData The data to update in sessionStorage.
   */
  updateSessionStorage: function (sessionData) {
    Object.keys(sessionData).forEach((key) => {
      sessionStorage.setItem(key, sessionData[key]);

      /**
       * The easiest way to deal with the changing user is to refresh the user page.
       */
      if (key == "user") {
        window.location.reload();
      }
    });
  },
};

export default AuthenticationService;
